import React from 'react';
import PropTypes from 'prop-types';

import ProductPreview from '../../productPreview/ProductPreview';
import {currencyFormat} from '../../../util/locale';

import './ProductsListItem.css';

/**
 * Products list item component.
 */
export default function ProductsListItem({ product }) {
    return (
        <div className="ProductsListItem">
            <div className="ProductsListItem-1by1">
                <div className="ProductsListItem-1by1-content">
                    <ProductPreview product={product} />
                </div>
            </div>
            <h6 className={`ProductsListItem-brand ${product.brand ? '' : 'ProductsListItem-brand--missing'}`}>
                {product.brand || 'No Brand'}
            </h6>
            <h7 className="ProductsListItem-title">
                {product.title}
            </h7>
            <div className="ProductsListItem-price font-proxima">
                {currencyFormat(product.price, 'KRW')}
            </div>
        </div>
    );
}
ProductsListItem.propTypes = {
    product: PropTypes.shape({
        id:  PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        status: PropTypes.oneOf(['selling', 'sold']).isRequired,
        brand: PropTypes.string,
        external_url: PropTypes.string,
        price: PropTypes.number.isRequired,
        resizedSmallImages: PropTypes.arrayOf(PropTypes.string).isRequired
    }).isRequired
};
