import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from "react-router-dom";

import IconAdd from './assets/icon_add.svg';

import './CheckoutShipping.css';

/**
 * Subcomponent for the shipping address.
 * @param productId
 * @param shippingInfo
 * @returns {JSX.Element}
 * @constructor
 */
export default function CheckoutShipping({productId, shippingInfo}) {
    const navigate = useNavigate();

    const handleRegisterShippingInfo = () => {
        navigate(`/checkout/shipping/${productId}`);
    }

    return <div className='CheckoutShipping'>
        <div className='CheckoutShipping-header'>
            <div className='title'>배송지</div>
            {shippingInfo !== null && <div className='CheckoutShipping-change' onClick={handleRegisterShippingInfo}>주소변경</div>}
        </div>

        {shippingInfo === null ?
            <div className='CheckoutShipping-add' onClick={handleRegisterShippingInfo}>
                <img alt={'add'} src={IconAdd}  />
                <div className='text'>배송지 추가</div>
            </div>
            : <div className='CheckoutShipping-info-container'>
                <div className='column'>
                    받는분
                </div>
                <div>
                    <div>{shippingInfo.name}</div>
                    <div>({shippingInfo.postalCode}) {shippingInfo.address} {shippingInfo.addressDetails}</div>
                    <div>{shippingInfo.phoneNumber}</div>
                    <div>{shippingInfo.deliveryNotes}</div>
                </div>
            </div>}
    </div>;
}

CheckoutShipping.propTypes = {
    productId: PropTypes.string.isRequired,
    shippingInfo: PropTypes.object,
}
