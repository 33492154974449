import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import {PRODUCT_FRAGMENT} from "../../component/productPreview/ProductPreview";
import {gql} from "apollo-boost";
import {useQuery} from "@apollo/react-hooks";
import {useForm} from "react-hook-form";
import {Helmet} from "react-helmet";

import {checkoutUrl, webappURL} from "../../util/url";
import CheckoutShipping from "./CheckoutShipping";
import CheckoutButton from "./CheckoutButton";
import CheckoutInvoice from "./CheckoutInvoice";
import CheckoutProductDetails from "./CheckoutProductDetails";
import CheckoutPaymentMethod from "./CheckoutPaymentMethod";
import Loading from "../../component/loading/Loading";
import Error from "../../component/error/Error";
import MobileMaxWidthWrapper from "../../component/mobileMaxWidthWrapper/MobileMaxWidthWrapper";
import FormCheckbox from "../../component/rhf/formCheckBox/FormCheckBox";
import {useRegisterShippingInfo} from "../../store/useRegisterShippingInfo";

import './Checkout.css';
import PropTypes from 'prop-types';

// GraphQL queries
export const SEE_PRODUCT = gql`
    query SeeProduct($productId: Int!) {
        seeProduct(id: $productId) {
            ...ProductFragment
            id
            title
            category
            brand
            gender
            resizedSmallImages
            status
            price
            shipping_fee
            shipping_fee_island
            status
            size
            condition
            createdAt
            seller {
                id
                user {
                    id
                    username
                }
            }
        }
    }
    ${PRODUCT_FRAGMENT}
`;

/**
 * Checkout page.
 * @param hostname
 * @returns {JSX.Element}
 * @constructor
 */
export default function Checkout({hostname}) {
    const { register, watch, formState: { isValid } } = useForm();
    const {shippingInfo} = useRegisterShippingInfo();

    const agree1 = watch('agree1');
    const agree2 = watch('agree2');

    // form data
    const [orderDetails] = useState({
        paymentMethod: 'CARD',
        shippingInfo: shippingInfo,
    });

    // fetch product details
    const {productId: encodedProductId} = useParams();
    const productId = parseInt(encodedProductId, 36);


    const {loading, error, data} = useQuery(
        SEE_PRODUCT,
        {
            fetchPolicy: 'network-only',
            returnPartialData: false,
            variables: {
                productId
            }
        }
    );

    if (loading) {
        return <Loading />;
    } else if (error) {
        return <Error error={error} />;
    }

    return <MobileMaxWidthWrapper className={'max-width-800'}>
        <Helmet>
            <title>주문/결제 - 후루츠패밀리</title>
            <link rel="canonical" href={webappURL(hostname) + checkoutUrl(data.seeProduct)} />
        </Helmet>

        <div className='Checkout-container'>
            <h1 className='page-title'>주문 / 결제</h1>
            <h4 className="Checkout-title">
                상품 정보
            </h4>

            <CheckoutProductDetails product={data.seeProduct} />

            {data.seeProduct.status === 'sold' ? (
                <div className="Checkout-soldout">
                    죄송합니다.<br />
                    아쉽지만 품절이 되었습니다 😢
                </div>
            ) : (
                <div className="Checkout-order">

                    <CheckoutInvoice product={data.seeProduct}
                                     shippingInfo={orderDetails.shippingInfo} />
                    <div className="Checkout-divider" />
                    <CheckoutShipping productId={encodedProductId} shippingInfo={orderDetails.shippingInfo} />
                    <div className="Checkout-divider" />
                    <CheckoutPaymentMethod />
                    <div className="Checkout-divider" />

                    <div className="Checkout-agree-container">
                        <div className="Checkout-agree-list">
                            <div className={`Checkout-agree-text ${agree1 ? 'Checkout-select-text' : 'Checkout-unselect-text'}`}>
                                주문할 상품의 결제,배송,주문정보를<br/>확인하였으며 이에 동의합니다.
                            </div>
                            <FormCheckbox register={register} name={'agree1'} required={true} />
                        </div>
                        <div className="Checkout-agree-list">
                            <div className={`Checkout-agree-text ${agree2 ? 'Checkout-select-text' : 'Checkout-unselect-text'}`}>
                                배송 완료 72시간 후 자동으로 구매확정 됩니다.
                            </div>
                            <FormCheckbox register={register} name={'agree2'} required={true} />
                        </div>
                        <CheckoutButton isValid={isValid && orderDetails.shippingInfo !== null} productId={productId} product={data.seeProduct} orderDetail={orderDetails} />
                    </div>
                </div>
            )}
        </div>
    </MobileMaxWidthWrapper>
}

Checkout.propTypes = {
    hostname: PropTypes.string.isRequired,
}
