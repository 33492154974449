import React, {} from "react";
import {Helmet} from "react-helmet";
import {searchUrl, webappURL} from "../../util/url";
import {NavLink, useParams} from "react-router-dom";
import SearchProductContainer from "./searchProductContainer/SearchProductContainer";
import {useQuery} from "@apollo/react-hooks";
import Loading from "../../component/loading/Loading";
import Error from "../../component/error/Error";
import {ALL_CATEGORIES, CATEGORY_ALL} from "../discover/Discover";
import {META_TAG_TITLE} from "../../constants/constants";

import './Search.css';
import PropTypes from 'prop-types';

/**
 * Search page
 * @param hostname
 * @returns {JSX.Element}
 * @constructor
 */
export default function Search({hostname}) {
    const {
        keyword,
        categoryName: partiallyEncodedCategoryName = CATEGORY_ALL.name // default parameter value
    } = useParams();

    const categoryName = decodeCategoryName(partiallyEncodedCategoryName);
    // fetch categories
    const { loading, error, data } = useQuery(ALL_CATEGORIES);
    if (loading) return <Loading />;
    if (error) return <Error error={error} />;

    const allCategories = getAllCategories(data);
    const selectedCategory = getSelectedCategory(allCategories, categoryName);

    // build the UI elements
    const categoryItems = allCategories ?
        allCategories.map(category => (
            <li className="Search-filter-item"
                key={category.name}>
                <NavLink className="Search-filter-toggle btn btn-outline-dark font-nhaasgrotesk" to={searchUrl(keyword, category)}
                         replace={true} end={true} >
                    {category.name}
                </NavLink>
            </li>
        )) :
        null;

    return (
        <section className="Search container">
            <Helmet>
                <title>{keyword} | {META_TAG_TITLE}</title>
                <meta name="description"
                      content={`${keyword}의 검색 결과 | 빈티지 세컨핸드 패션 중고거래는 후루츠. 유니크한 디자이너 브랜드 제품부터 구하기 힘든 구시즌 아카이브 명작까지 후루츠에서 한번에.`} />
                <link rel="canonical" href={webappURL(hostname) + searchUrl(keyword, selectedCategory)} />
            </Helmet>

            <div>
                <ul className="Search-filter list-unstyled d-flex flex-wrap">
                    {categoryItems}
                </ul>
                <SearchProductContainer searchQuery={keyword} selectedCategory={selectedCategory} />
            </div>
        </section>
    );
}

function decodeCategoryName(partiallyEncodedName) {
    try {
        return decodeURIComponent(partiallyEncodedName)
    } catch (error) {
        return partiallyEncodedName;
    }
}

function getAllCategories(data) {
    if (!data) return null;

    return [CATEGORY_ALL, ...data.getCategories];
}

function getSelectedCategory(categories, categoryName){
    if(!categories || !categoryName){
        return null;
    }

    return categories.find(category => category.name === categoryName);
}

Search.propTypes = {
    hostname: PropTypes.string.isRequired,
}
