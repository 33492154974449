import React from 'react';
import {Helmet} from 'react-helmet';
import {META_TAG_TITLE} from "../../constants/constants";

/**
 * A component which populates the webapp head element with default metadata.
 * @return {*}
 * @constructor
 */
export default function Head() {
    return (
        <Helmet>
            <title>{META_TAG_TITLE}</title>
            <meta name="description"
                  content="유니크한 빈티지, 세컨핸드부터 구하기 힘든 하이엔드 디자이너 브랜드까지 후루츠에서 한번에." />
        </Helmet>
    );
}
