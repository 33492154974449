// code copied as is from the mobile app: FruitsFamily/src/screens/checkout/utils/CheckoutUtils.js
export const checkOutSidePostCode = code => {
  if (!code || !code.length) return false;

  const postcode = parseInt(code);

  if (postcode >= 22386 && postcode <= 22388) {
    return true;
  }

  if (postcode >= 23004 && postcode <= 23010) {
    return true;
  }

  if (postcode >= 23100 && postcode <= 23116) {
    return true;
  }

  if (postcode >= 23124 && postcode <= 23136) {
    return true;
  }

  if (postcode === 31708) return true;
  if (postcode === 32133) return true;
  if (postcode === 33411) return true;

  if (postcode >= 40200 && postcode <= 40240) {
    return true;
  }

  if (postcode >= 46768 && postcode <= 46771) {
    return true;
  }

  if (postcode >= 52570 && postcode <= 52571) {
    return true;
  }

  if (postcode >= 53031 && postcode <= 53033) {
    return true;
  }

  if (postcode >= 53089 && postcode <= 53104) {
    return true;
  }

  if (postcode === 54000) return true;

  if (postcode >= 56347 && postcode <= 56349) {
    return true;
  }

  if (postcode >= 57068 && postcode <= 57069) {
    return true;
  }

  if (postcode >= 58760 && postcode <= 58762) {
    return true;
  }

  if (postcode >= 58800 && postcode <= 58810) {
    return true;
  }

  if (postcode >= 58816 && postcode <= 58818) {
    return true;
  }

  if (postcode === 58826) return true;

  if (postcode >= 58828 && postcode <= 58866) {
    return true;
  }

  if (postcode >= 58953 && postcode <= 58958) {
    return true;
  }

  if (postcode >= 59102 && postcode <= 59103) {
    return true;
  }

  if (postcode === 59106) return true;
  if (postcode === 59127) return true;
  if (postcode === 59129) return true;

  if (postcode >= 59137 && postcode <= 59166) {
    return true;
  }

  if (postcode === 59421) return true;
  if (postcode === 59531) return true;
  if (postcode === 59551) return true;
  if (postcode === 59563) return true;
  if (postcode === 59568) return true;
  if (postcode === 59650) return true;
  if (postcode === 59766) return true;

  if (postcode >= 59781 && postcode <= 59790) {
    return true;
  }

  if (postcode >= 63000 && postcode <= 63644) {
    return true;
  }

  return false;
};
