import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Helmet} from "react-helmet";
import Button from "../../component/button/Button";
import {gql} from "apollo-boost";
import {useMutation} from "@apollo/react-hooks";
import {useNavigate} from "react-router-dom";
import {getGraphQLErrorMsg} from "../../util/util";
import {logAddPaymentInfo, logPurchase} from "../../util/analytics";
import toast from "react-hot-toast";
import Loading from "../../component/loading/Loading";

import './CheckoutButton.css';

let paymentModuleInitialized = false;

const GET_PREPARE_PAYMENT = gql`
  mutation PreparePayment(
    $productId: ID!
    $paymentMethod: PaymentMethod!
    $name: String!
    $phoneNumber: String!
    $postalCode: String!
    $address: String!
    $addressDetails: String!
    $deliveryNotes: String!
  ) {
    preparePayment(
      productId: $productId
      paymentMethod: $paymentMethod
      name: $name
      phoneNumber: $phoneNumber
      postalCode: $postalCode
      address: $address
      addressDetails: $addressDetails
      deliveryNotes: $deliveryNotes
    )
  }
`;

export default function CheckoutButton({isValid, productId, product, orderDetail}) {
    const navigate = useNavigate();
    const [iamportModalOpened, setIamportModalOpened] = useState(false);
    const [preparePayment] = useMutation(GET_PREPARE_PAYMENT);

    async function submitPayment(event) {
        event.preventDefault();

        setIamportModalOpened(true);
        preparePayment({
            variables: {
                productId: productId,
                paymentMethod: orderDetail.paymentMethod,
                name: orderDetail.shippingInfo.name,
                phoneNumber: orderDetail.shippingInfo.phoneNumber,
                postalCode: orderDetail.shippingInfo.postalCode,
                address: orderDetail.shippingInfo.address,
                addressDetails: orderDetail.shippingInfo.addressDetails,
                deliveryNotes: orderDetail.shippingInfo.deliveryNotes,
            }
        }).then(response => {
            processPayment(response);
        }).catch(error => {
            setIamportModalOpened(false);
            toast(getGraphQLErrorMsg(error.message));
        });
    }

    //아임포트 결제 실행
    const processPayment = (response) => {
        const requestPayData = response.data.preparePayment;
        //모바일용을 위한 추가 redirect_url
        requestPayData.m_redirect_url = `${window.location.protocol}//${window.location.host}/checkout/complete`;

        if (!paymentModuleInitialized) {
            paymentModuleInitialized = true;
            window.IMP.init(requestPayData.user_code);
        }

        //결제 정보 저장
        sessionStorage.setItem('lastProductInfo', JSON.stringify({
            'product' : product,
            'amount': requestPayData.amount,
        }));

        window.IMP.request_pay(requestPayData,
            response => {
                //PC일 경우에만 호출됨.
                if (response.success) {
                    logPurchase('webapp', product, response.imp_uid, requestPayData.amount, requestPayData.amount - product.price);
                    setTimeout(() => {
                        toast('정상적으로 결제가 되었습니다.');
                        setIamportModalOpened(false);
                        navigate('/my/purchased');
                    }, 3000);
                } else {
                    alert(response.error_msg);
                    setIamportModalOpened(false);
                }

                //결제 완료 후 삭제
                sessionStorage.removeItem('lastProductInfo');
            }
        );
        logAddPaymentInfo('webapp', product, orderDetail.paymentMethod);
    }

    return <div>
        <Helmet>
            <script src="https://cdn.iamport.kr/v1/iamport.js"></script>
        </Helmet>
        <Button onClick={submitPayment} disabled={!isValid} text={!iamportModalOpened? '안전 결제하기' : '결제 진행중'} full={true} />
        {iamportModalOpened &&
            <div className='CheckoutButton-loading-container'>
                <Loading />
            </div>
        }
    </div>
}

CheckoutButton.propTypes = {
    isValid: PropTypes.bool.isRequired,
    productId: PropTypes.number.isRequired,
    product: PropTypes.object.isRequired,
    orderDetail: PropTypes.object.isRequired,
}
