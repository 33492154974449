import React from 'react';
import {Link} from 'react-router-dom';

import {DISCOVER_TAB} from '../../page/discover/Discover';
import {discoverUrl, downloadUrl, homeUrl} from '../../util/url';

import fruitsfamilyLogo from './assets/logo.svg';
import nicepayEscrow from './assets/nicepayEscrow.png';
import './Footer.css';

/**
 * Footer page component.
 */
export default function Footer() {
    return (
        <footer className="Footer container my-4 pt-4 my-md-5 pt-md-5 border-top">
            <div className="Footer-row row">
                <div className="Footer-col col-12 col-md">
                    <img className="Footer-logo mb-3"
                         alt="FruitsFamily™"
                         src={fruitsfamilyLogo} />
                </div>
                <div className="Footer-col col-6 col-md">
                    <h5 className="Footer-title">
                        웹
                    </h5>
                    <ul className="Footer-menu list-unstyled text-small">
                        <li>
                            <Link className="Footer-link text-muted"
                                  to={homeUrl()}>
                                홈
                            </Link>
                        </li>
                        <li>
                            <Link className="Footer-link text-muted"
                                  to={discoverUrl(DISCOVER_TAB.PRODUCT)}>
                                마켓
                            </Link>
                        </li>
                        <li>
                            <Link className="Footer-link text-muted"
                                  to={discoverUrl(DISCOVER_TAB.SHOP)}>
                                판매자
                            </Link>
                        </li>
                        <li>
                            <Link className="Footer-link text-muted"
                                  to={downloadUrl()}>
                                다운로드
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="Footer-col col-6 col-md">
                    <h5 className="Footer-title">
                        지원
                    </h5>
                    <ul className="Footer-menu list-unstyled text-small">
                        <li>
                            <a className="Footer-link text-muted"
                               href="https://intercom.help/fruits-family-co"
                               rel="noopener noreferrer"
                               target="_blank">
                                고객센터
                            </a>
                        </li>
                        <li>
                            <a className="Footer-link text-muted"
                               href="https://support.fruitsfamily.com/sell"
                               rel="noopener noreferrer"
                               target="_blank">
                                판매
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="Footer-col col-6 col-md">
                    <h5 className="Footer-title">
                        소셜
                    </h5>
                    <ul className="Footer-menu list-unstyled text-small">
                        <li>
                            <a className="Footer-link text-muted"
                               href="https://www.instagram.com/fruitsfamily.co/"
                               rel="noopener noreferrer"
                               target="_blank">
                                인스타그램
                            </a>
                        </li>
                        <li>
                            <a className="Footer-link text-muted"
                               href="https://www.facebook.com/FruitsFamily-108768747380238"
                               rel="noopener noreferrer"
                               target="_blank">
                                페이스북
                            </a>
                        </li>
                        <li>
                            <a className="Footer-link text-muted"
                               href="https://m.post.naver.com/fruitsfamilyco"
                               rel="noopener noreferrer"
                               target="_blank">
                                네이버 포스트
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="Footer-col col-6 col-md">
                    <h5 className="Footer-title">
                        서비스
                    </h5>
                    <ul className="Footer-menu list-unstyled text-small">
                        <li>
                            <a className="Footer-link text-muted"
                               href="https://intercom.help/fruits-family-co/articles/3683933"
                               rel="noopener noreferrer"
                               target="_blank">
                                이용약관
                            </a>
                        </li>
                        <li>
                            <a className="Footer-link text-muted"
                               href="https://intercom.help/fruits-family-co/articles/3683931"
                               rel="noopener noreferrer"
                               target="_blank">
                                개인정보
                            </a>
                        </li>
                        <li>
                            <a className="Footer-link text-muted"
                               href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=7558601442"
                               rel="noopener noreferrer"
                               target="_blank">
                                사업자 정보
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="Footer-legal Footer-row row justify-content-between align-items-center col-12">
                <div className="Footer-col">
                    <div>
                        <address>
                            (주)후루츠패밀리컴퍼니 대표이사 이재범<br/>
                            주소 서울특별시 용산구 한강대로 328<br/>
                            사업자등록번호 755-86-01442<br/>
                            통신판매업신고 2019-서울용산-0723 호<br/>
                            고객센터 문의 <a href="mailto:support@fruitsfamily.com">support@fruitsfamily.com</a>
                        </address>
                        <p className='mb-0'>
                            Copyright © FruitsFamily Company Inc. All right reserved
                        </p>
                    </div>
                </div>
                <div className='Footer-escrow-container'>
                    <a href="https://pg.nicepay.co.kr/issue/IssueEscrow.jsp?Mid=IMPfrui01m&CoNo=7558601442"
                       rel="noopener noreferrer"
                       target="_blank">
                        <img src={nicepayEscrow} alt={'escrow mark'} className='Footer-escrow-img'/>
                    </a>
                </div>
            </div>
        </footer>
    );
}

