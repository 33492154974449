import React, {useEffect} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {logPurchase} from "../../../util/analytics";
import Loading from "../../../component/loading/Loading";

/**
 * 결제 완료 Redirect 페이지
 */
export default function CheckoutComplete() {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();

  useEffect(() => {
      if (queryParameters.get('imp_success') === 'true') {
          const requestPayData = JSON.parse(sessionStorage.getItem('lastProductInfo'));
          logPurchase('webapp', requestPayData.product, queryParameters.get('imp_uid'), requestPayData.amount, requestPayData.amount - requestPayData.price);
          setTimeout(() => {
              navigate('/my/purchased');
          }, 1000);
      } else {
          alert(queryParameters.get('error_msg'));
          navigate('/');
      }
      //결제 완료 후 삭제
      sessionStorage.removeItem('lastProductInfo');
  }, []);

  return <Loading />;
}
