import React from 'react';
import ProductsList from "../../../component/productsList/ProductsList";
import {gql} from "apollo-boost";
import PropTypes from 'prop-types';
import {PRODUCT_FRAGMENT} from "../../../component/productPreview/ProductPreview";
import {PRODUCT_DETAILS_PRELOAD_FRAGMENT} from "../../product/Product";
import {CATEGORY_ALL} from "../../discover/Discover";

const SEE_PRODUCTS = gql`
    query SeeProducts($filter: ProductFilter!, $offset: Int, $limit: Int, $sort: String) {
        seeProducts(filter: $filter, offset: $offset, limit: $limit, sort: $sort) {
            ...ProductFragment
            ...ProductDetailsPreloadFragment
            price
        }
    }
    ${PRODUCT_FRAGMENT}
    ${PRODUCT_DETAILS_PRELOAD_FRAGMENT}
`;

const SearchProductContainer = ({ className, searchQuery, selectedCategory }) => {
    return (
        <div className={className}>
            {
                <ProductsList
                    itemsProperty="seeProducts" query={SEE_PRODUCTS}
                    variables={{
                        filter: {
                            query: searchQuery,
                            category: selectedCategory === CATEGORY_ALL ? undefined : selectedCategory.name
                        },
                        sort: 'POPULAR',
                    }}
                limit={40}
                />
            }

        </div>
    );
};

SearchProductContainer.propTypes = {
    className: PropTypes.string,
    searchQuery: PropTypes.string,
    selectedCategory: PropTypes.object,
};

export default SearchProductContainer;
