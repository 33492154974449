import {create} from "zustand";

/**
 * 배송 정보 등록
 */
export const useRegisterShippingInfo = create((set) => ({
    /**
     *  name: '',
     *  phoneNumber: '',
     *  address: '',
     *  addressDetails: '',
     *  postalCode: '',
     *  deliveryNotes: '',
     */
    shippingInfo: null,

    setShippingInfo: function(shippingInfo) {
        set({ shippingInfo: shippingInfo });
    }
}));
